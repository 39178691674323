.gm-report-error-container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.gm-report-error-form {
    width: 75%;
    height: 500px;

    padding: 10px;

    background-color: white;
}