.pga-model-builder-page-container {
    background-color: #ecf0f5;
    width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.pga-model-builder-container {
    padding: 20px;

    min-width: 1024px;
    width: 80%;
    background-color: white;
}

.pga-model-builder-tab {
    display: flex;
    flex-wrap: wrap;

    padding-top: 20px;

    gap: 20px;
}

.pga-model-builder-field-select {
    height: 38px;

    width: 100%;
}

.pga-model-builder-new-calc-input-container {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .pga-model-builder-container {
        width: 95vw;
        min-width: 95vw;

        padding: 5px;
    }

    .pga-model-builder-stat-table-container {
        width: 100%;
        overflow-x: scroll;
    }

    .pga-model-builder-tab {
        flex-direction: column;
    }
}