.pga-model-builder-button {
    width: 100%;
    height: 38px;

    border-radius: 5px;

    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.pga-model-builder-button-pending {
    width: 100%;
    height: 38px;

    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.5);
}

.pga-model-builder-button:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}