.link-button {
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.link-button:hover {
    text-decoration: underline;
}