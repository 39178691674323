.pga-model-builder-input-config-container {
    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
}

.pga-model-builder-input-config-column {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0;
    column-gap: 30px;
    row-gap: 10px;
}

.pga-model-builder-input-config-submit {
    width: 100%
}

@media screen and (max-width: 768px) {
    .pga-model-builder-input-config-container {
        flex-direction: column;
    }
}