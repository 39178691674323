.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  object-fit: contain;
}

.hero-content {
  margin-left: 40px;
}

@media screen and (max-width: 960px) {
  .hero-content {
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hero-content {
    margin-left: 20px;
  }
}
