.podcast-upload-status-container {
    position: fixed;
    bottom: 0;
    right: 0;

    width: 500px;
    background: darkgrey;

    text-align: center;
}

.podcast-upload-status-entry {
    height: 50px;
    background: white;
    margin: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 18px;
}

.podcast-upload-success {
    background: var(--gup-green);
}

.podcast-upload-failure {
    background: #cc0000;
    color: white;
}

.podcast-upload-pending {
    background: #ffb347;
}