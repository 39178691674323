.select-field-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;
}

.select-field-option {
    flex: 1 1 auto;
    color: white;

    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.select-field-option-unselected {
    background-color: #3c8dbc;
}

.select-field-option-unselected:hover {
    background-color: #367fa9;
}

.select-field-option-selected {
    background-color: #286090;
}

.select-field-option-selected:hover {
    background-color: #204d74;
}