.stack-creator-container {
    width: 100%;
    height: 100%;
    background: white;

    padding: 20px;

    overflow: scroll;
}

.stack-save-error {
    color: red;
    font-weight: bold;
}

.stack-name {
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .stack-name {
        width: 50%;
    }
}