.stat-engine-injury::after {
    content: "+";
    height:10px;
    width:10px;
    font-size:20px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    font-family:courier;
    color:red;
}