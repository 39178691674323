:root {
  --mobile-s: 320px;
  --mobile-m: 375px;
  --mobile-l: 425px;
  --tablet: 768px;
  --laptop: 1024px;
  --laptop-l: 1440px;
  --desktop: 2560px;

  --navbar-height: 72px;
  --footer-height: 200px;

  --gup-green: #1fa980;
  --gray-900: #111114;
  --gray-800: #222224;
  --gray-700: #444444;
  --gray-600: #666664;
  --gray-500: #888884;
  --gray-400: #aaaaa4;
  --gray-300: #ccccc4;
  --gray-200: #e2e2e4;
  --gray-100: #f9f9f9;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: "Raleway", serif;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("assets/fonts/Raleway-Regular.ttf") format("truetype");
}

.home {
  display: flex;
  height: 90vh;
  font-size: 3rem;
}

button {
  outline: none;
  cursor: pointer;
}

button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.text-align-center {
  text-align: center;
}

.noscroll {
  overflow: hidden;
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

/* Prettier scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}