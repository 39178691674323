.forgot-password-container {
    max-width: 500px;

    margin: calc(var(--navbar-height) + 28px) auto auto;
}

.forgot-password-header {
    text-align: center;
}

.forgot-password-submitted {
    text-align: center;
}

.forgot-password-form-container {
    padding: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: 4px;

    margin: auto;

}

.forgot-password-form-container label:first-child,
.forgot-password-form-container .form-control:first-child {
    margin-top: 0;
}

.form-label {
    font-weight: bold;
    display: flex;
}

.form-input {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
}

.form-invalid .form-input {
    border-color: red;
}

.form-input:hover {
    border: 2px solid var(--gray-300);
}

.form-submit {
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: 4px;
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
    color: white;
    text-decoration: none;
    font-weight: bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
    transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    margin-top: 1rem;
    margin-bottom: 0;
}

.form-submit:hover {
    cursor: pointer;
    background: var(--gray-900);
}
.form-invalid .form-feedback {
    color: red;
}

.form-valid .form-input {
    border-color: green;
}

.form-valid .form-feedback {
    color: green;
}