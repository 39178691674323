.gm-rules-select {
    width: 90%;
    margin: auto;
}

.gm-rules-staff-stacks {
    width: 90%;
}

@media only screen and (min-width: 1024px) {
    .gm-configure-rules-container {
        width: 1000px;
        margin: auto;
    }

    .gm-rules-stack-lists {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
    }

    .gm-rules-select {
        width: 70%;
    }

    .gm-rules-staff-stacks {
        width: 25%;
    }
}