.tos {
    width: 100%;
    padding: 10px;
}

@media only screen and (min-width: 1024px) {
    .tos {
        width: 1000px;
        margin: auto;
    }
}

@media only screen and (min-width: 1440px) {
    .tos {
        width: 1170px;
    }
}