.redzone-splits-display-container {
    display: flex;
    justify-content: space-evenly;
}

.redzone-team-splits {
    display: block;
    width: 50vw;

    height: 50vh;
    overflow-y: scroll;
}

.redzone-split-pie-chart {
    width: 15vw;

    display: flex;
    align-items: center;
}

.redzone-team-abbreviation {
    text-align: center;
    font-weight: bolder;
    cursor: pointer;
}

.redzone-team-split-row:hover {
    cursor: pointer;
}

.redzone-team-split-wrapper {
    display: flex;

    flex-wrap: nowrap;
}

.redzone-team-split-wrapper:hover {
    cursor: pointer;
}

.redzone-team-split {
    height: 25px;
    color: white;
    font-weight: bolder;

    display: flex;
    align-items: center;
    justify-content: center;
}

.redzone-team-split-defocus {
    opacity: 0.3;
}

.redzone-pass-split {
    background-color: #374649;
}

.redzone-rush-split {
    background-color: #039d91;
}