.signup-container {
  width: 100%;
  padding: 20px;
  background: #f7f8f9;
  margin: 72px auto auto;
  min-height: calc(100vh - 72px)
}

.signup-input, .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.signup-input {
  padding: 10px 20px;
  width: 100%;
}

.signup-label {
  margin-bottom: 10px;
}

.signup-input:focus {
  outline: none;
}

.signup-input:focus-visible, .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

