.twitter-testimonials-screenshot {
    width: 100%;
}
.twitter-testimonials-header {
    text-align: center;
}

.twitter-testimonials-text {
    text-align: center;
}

@media only screen and (min-width: 1024px) {
    .twitter-testimonials-screenshots {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .twitter-testimonials-screenshot {
        width: 55%;
        margin-bottom: 10px;
    }
}