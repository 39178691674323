.exposures-analysis-breakdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 10px 0 10px;
}

.exposures-analysis-position {
    text-align: center;
    padding: 10px;
}