#register-container {
  margin-top: 100px;
}

.register-header {
  text-align: center;
}

.register-byline {
  width: 80%;
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  #register-container {
    width: 1024px;
    margin: 100px auto auto auto;
  }
}