.page-not-found-container {
    width: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-not-found-image {
    width: 75%;
    height: inherit;
    margin: auto;

    border-radius: 20px;
}

.page-not-found-header {
    font-size: 48px;
    margin-bottom: 10px;
}

.page-not-found-byline {
    margin: 0 0 15px 0;
}

@media only screen and (min-width: 1024px) {
    .page-not-found-container {
        width: 750px;
        margin: auto;
    }
}