.account-container {
    padding: 20px;

    background: #f7f8f9;

    min-height: calc(100vh - var(--navbar-height));

    display: flex;
    justify-content: center;
}

.account-links {
    margin-bottom: 10px;
}

.account-link {
    font-weight: bolder;
    text-decoration: none;
    padding-right: 10px;
}

.account-link-active {
    color: #0E7500;
    cursor: pointer;
}

.account-link-active:hover {
    text-decoration: underline;
}

.account-link-inactive {
    color: black;
    cursor: default;
}

@media only screen and (min-width: 1240px) {
    .account-details-container {
        width: 50%;
    }
}