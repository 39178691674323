.pga-live-movers-container {
    display: none;
}

.pga-live-movers-container h1 {
    text-align: center;
}

.pga-live-movers-player {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pga-live-movers-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.pga-live-movers-up-arrow {
    transform: rotate(-90deg);
}

.pga-live-movers-down-arrow {
    transform: rotate(90deg);
}

@media only screen and (min-width: 1100px) {
    .pga-live-movers-container {
        display: block;
        width: 25%;
    }
}