.overlay {
    position: fixed;
    width: 100vw;
    height: calc(100vh - var(--navbar-height));

    background-color:rgba(0, 0, 0, 0.5);

    left: 0;
    top: calc(var(--navbar-height));

    display: none;
    z-index: 999;
}

.overlay-show {
    display: flex;

    overscroll-behavior: contain;
}

