.affiliates-container {
    padding: 20px;
}

.affiliates-container > hr {
    width: 99%;
}

@media only screen and (min-width: 1024px) {
    .affiliates-container {
        width: 70%;
        margin: auto;
    }
}