.dk-info-overlay-tabs-container {
    width: 100%;
}

.dk-info-overlay-slate-list-option {
    height: 50px;

    border: 1px solid black;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;
    cursor: pointer;

    font-weight: bold;
}

.dk-info-overlay-slate-list-option-selected {
    background-color: var(--gup-green);
    color: white;
}

.dk-info-overlay-slate-list-option-selected:hover {
    background-color: palegreen;
    color: black;
    border: 3px solid black;
}

.dk-info-overlay-slate-list-option-unselected:hover {
    border: 3px solid var(--gup-green);
}

.dk-info-overlay-slate-list-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.dk-info-overlay-slate-list-section {
    width: 100%;
    padding-top: 10px;
}

@media screen and (max-width: 768px) {
    .dk-info-overlay-slate-list-container {
        flex-direction: column;
        column-gap: 10px;
    }

    .dk-info-overlay-slate-list-section {
        width: 100%;
    }

    .dk-info-overlay-slate-list-option-selected:hover {
        border: 1px solid black;
    }

    .dk-info-overlay-slate-list-option-unselected:hover {
        border: 1px solid black;
    }

    .dk-info-overlay-headshot-image {
        display: none;
    }
}