.dk-info-overlay {
    width: 70vw;
    min-height: 60vh;
    max-height: 80vh;
    margin: auto;

    padding: 20px;
    border-radius: 20px;

    background-color: white;
}

.dk-slate-list-container {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .dk-info-overlay {
        width: 90vw;
        padding: 10px 10px 10px 10px;

        overflow-y: inherit;
        max-height: 90vh;
    }
}