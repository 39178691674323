.avatars-container {
    width: 100%;
    text-align: center;
}

.avatars-header {
    padding-top: 15px;
}

.avatars-flex-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    align-items: center;
}

.avatars-image {
    width: 80%;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .avatars-flex-wrapper {
        flex-direction: row;

        justify-content: space-evenly;
    }

    .avatars-image {
        width: 40%;
    }
}

@media only screen and (min-width: 1024px) {
    .avatars-container {
        width: 1024px;
        margin: auto;
    }

    .avatars-image {
        width: 30%;
    }
}