.average-stats-display-container {
    width: 10vw;
}

.average-stat-value-green {
    color: var(--gup-green);
}

.average-stat-value {
    text-align: center;
}

.average-stat-label {
    text-align: center;
}