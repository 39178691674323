.membership-feature-item {
    margin-top: 10px;
}

.feature-included {
    color: darkgreen;
    font-weight: bold;
}

.feature-not-included {
    color: red;
}

.feature-icon {
    width: 16px;
    height: 16px
}