.tab-list {
  padding-left: 0;
  margin: 0;

  display: flex;
  justify-content: center;

  overflow-x: scroll;
  overflow-y: hidden;
}

.tabs-header {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #ccc;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;

  background-color: white;

  font: inherit;
  cursor: pointer;
  color: #767676;

  border: 2px solid #ccc;
  border-bottom: none;

  width: 125px;

  border-radius: 10px 10px 0 0;

  font-weight: bold;
}

.tab-list-item:hover {
  border-color: #2c2c2c;
  border-bottom: none;
}

.tab-list-active {
  background-color: white;
  border-color: #2c2c2c;
  border-bottom: 2px solid white;
  margin-bottom: -2px;

  color: #2c2c2c;
}

.tab-list-active:hover {
  border-bottom: 2px solid white;
}

@media only screen and (min-width: 769px) {
  .tab-list {
    display: flex;
    justify-content: center;

    overflow-x: hidden;
    overflow-y: hidden;
  }
}

@media only screen and (min-width: 1024px) {
  .tab-list {
    display: flex;
    justify-content: center;
  }

  .tab-list-item {
    width: 250px;
    height: 50px;
    margin-left: 2px;
    margin-right: 2px;
  }
}