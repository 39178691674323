.change-password-form {
    width: 90%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

@media only screen and (min-width: 1024px) {
    .change-password-form {
        width: 500px;
    }
}