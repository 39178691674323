.signin-page-container {
    background: #f7f8f9;
    width: 100%;
}

.signin-container {
    width: 100%;
    padding: 20px;
    background: #f7f8f9;
    margin: 72px auto auto;
    min-height: calc(100vh - 72px);
}

.signin-input {
    box-sizing: border-box;

    height: 40px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    padding: 10px 20px;
    width: 100%;

    margin-bottom: 20px;
}

.signin-input {
    padding: 10px 20px;
    width: 100%;
}

.signin-label {
    margin-bottom: 10px;
}

.signin-input:focus {
    outline: none;
}

.signin-input:focus-visible {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

