.pga-model-builder-section {
    min-height: 50px;

    background-color: #f7f7ff;
    border-color: #3d8dbc;
    border-top: 5px solid #3d8dbc;

    flex: 1 1 auto;
    wrap-option: after;

    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.pga-model-builder-section-small {
    width: 30%;
}

.pga-model-builder-section-large {
    width: 100%;
}