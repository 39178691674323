.game-result-logo {
    width: 35px;
    height: inherit;
}

.game-result-winner {
    color: darkgreen;
}

.game-result-loser {
    color: red;
}

.show-schedule-button {
    width: 25px;
    height: 150px;
    background-color: lightgray;
    text-align: center;

    cursor: pointer;

    border-radius: 0px 10px 10px 0px;
}

.show-schedule-button > span {
    writing-mode: vertical-rl;

    text-align: center;

    height: 100%;
    font-weight: bold;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}