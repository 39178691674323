.article-image {
    max-width: 100%;
    height: inherit;
}

.article-player-mention {
    color: green;
    font-weight: bold;
    font-size: inherit;
    border-bottom: green dotted 2px;
    border-top: none;
    border-left: none;
    border-right: none;

    background: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;

    text-align: center;
}

.article-player-mention:hover {
    color: darkgreen;
    border-bottom: darkgreen solid 2px;
}

#article-player-tooltip {
    position: fixed;
    top: 0;
    right: 0;
    background-color: black;
    color: white;
    width: 200px;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: 10px;
}

#article-player-tooltip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}