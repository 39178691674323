.gm-select-sport-complete {
    margin-top: calc(var(--navbar-height) + 25px);
    margin-bottom: 25px;
    text-align: center;
}

@media only screen and (min-width: 1024px) {
    .gm-select-sport-container {
        width: 750px;
        margin: auto;
    }
}