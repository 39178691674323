.live-scorecard-filters-container {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
}

.live-scorecard-filters-container > label > * {
    z-index: 50;
    width: 100%;
    max-height: 120px !important;
}

.live-scorecard-filters-players {
    width: 100%;
}

.live-scorecard-import-gm-players {
    width: 100%;
}

@media only screen and (min-width: 1100px) {
    .live-scorecard-filters-container {
        display: flex;
        justify-content: space-between;
    }

    .live-scorecard-filters-container > label > * {
        z-index: 101;
        width: 100%;
        max-height: 120px !important;
    }

    .live-scorecard-filters-players {
        width: 78%;
    }

    .live-scorecard-import-gm-players {
        width: 18%;
    }
}