.nfl-stat-engine-page {

}

.nfl-stat-engine-filters {
    min-height: 100px;
    width: 100%;

    display: flex;

    align-items: center;
    justify-content: space-evenly;
}

.nfl-stat-engine-week-select-container {
    width: 50%;
}

.nfl-stat-engine-week-select {
    display: flex;

    justify-content: space-evenly;

    flex-wrap: wrap;

    border: 1px solid black;
}

.nfl-stat-engine-week-option {
    min-width: 20px;

    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    width: calc(100% / 18);

    cursor: pointer;

    font-weight: bold;
}

.nfl-stat-engine-week-option-unselected:hover {
    background-color: lightgray;
}

.nfl-stat-engine-week-option-selected {
    background-color: darkgray;
}

.nfl-stat-engine-week-option-selected:hover {
    background-color: lightgray;
}

.nfl-stat-engine-container > div > table > thead > tr > th {
    top: 0 !important;
}