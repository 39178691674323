.feature-panel {
    min-height: 70vw;
    width: 100vw;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feature-panel-img {
    width: 80vw;
    height: 60vw;
    object-fit: cover;

    border-radius: 10px;

    -webkit-box-shadow: 0px 6px 17px 3px rgba(0,0,0,0.43);
    box-shadow: 0px 6px 17px 3px rgba(0,0,0,0.43);
}

.feature-panel-text {
    width: 80vw;

    text-align: center;
}

.feature-panel-first {
    order: 1
}

.feature-panel-second {
    order: 1
}

.feature-panel-byline {
    color: #686d73;

    margin-block-end: 5px;
}

.feature-panel-title {
    margin-top: 0;

    text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
    .feature-panel {
        width: 90vw;
        min-height: auto;

        padding: 50px;

        margin: auto;
        
        flex-direction: row;
    }

    .feature-panel-second {
        order: 2;

        margin-left: 50px;
    }

    .feature-panel-img {
        width: 400px;
        height: 300px;

        border-radius: 50px;
    }

    .feature-panel-text {
        width: 600px;
    }
}

@media only screen and (min-width: 1440px) {
    .feature-panel-img {
        width: 600px;
        height: 400px;

        border-radius: 50px;
    }
}