.team-offensive-stat-line-chart {
}

.team-offensive-stat-display-group {
    display: flex;
    align-items: center;

    width: 90vw;
}

@media only screen and (min-width: 1024px) {
    .team-offensive-stat-display-group {
        width: auto;
    }
}