.nfl-projections-container {
    max-width: 1024px;
    margin: auto;
    height: 100%;

    padding: 20px;
}

.nfl-projections-table-container {
    overflow-x: scroll;
}

.nfl-projections-table-container > div > table > thead > tr > th {
    top: 0 !important;
}