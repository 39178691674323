.position-filter {
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    width: 50px;
    height: 30px;
    border: 1px gray solid;
    border-radius: 10px;
    color: gray;
}

.position-filter-selected {
    color: green;
    border-color: green;
    border-width: 2px;
    font-weight: bold;
}