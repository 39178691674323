.fade-in-section {
    padding: 0 16px 16px;
    margin: 0 16px 16px;
    opacity: 0;
    transform: translate(0, 50%);
    visibility: hidden;
    transition: opacity 300ms ease-out, transform 300ms ease-out;
    will-change: opacity, visibility;

    display: flex;
}

.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}