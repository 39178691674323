.upload-podcast-container {
    margin-left: 20px;
}

.upload-podcast-title {
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .upload-podcast-title {
        width: 50%;
    }
}