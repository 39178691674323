#page-footer {
    background-color: #282a2d;

    width: 100%;
    height: var(--footer-height);

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}

.footer-link {
    order: 1;
    color: white;
    text-decoration: none;
    padding: 20px;
}

.footer-copyright {
    order: 2;
    color: white;

    padding-top: 20px;
}