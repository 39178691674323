.pga-scorecards-container {
    height: 100%;
    width: 100%;
}

.pga-scorecards-container > div > table > tbody > tr[role="row"] {
    cursor: pointer !important;
}

.pga-scorecard-entry {
    height: 30px;
    width: 30px;

    margin: 5px auto 5px auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

.pga-scorecard-one-over-par {
    color: red;
    border: 1px solid red;
}

.pga-scorecard-multi-over-par {
    color: red;
    border: 4px double red;
}

.pga-scorecard-one-under-par {
    color: green;
    border: 1px solid green;

    border-radius: 50%;
}

.pga-scorecard-multi-under-par {
    color: green;
    border: 4px double green;

    border-radius: 50%;
}