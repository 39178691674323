.hero-slider {
    --slider-height-percentage: inherit;
    --content-background-color: white;

    min-height: 310px;
    height: calc(50vh - 72px);

    display: block;
}

.awssld__content {
    display: block;
}

@media only screen and (min-width: 1024px) {
    .hero-slider {
        height: calc(80vh - 72px);

        --slider-transition-duration: 5000ms;
    }
}