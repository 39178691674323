.stat-table-container {
    height: 100%;
}

.stat-table-container thead {
    z-index: 100;
}

.stat-table-container thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.stat-table-container tbody tr td:first-child {
    position: sticky;
    left: 0;
}

.stat-table {
    border-spacing: 0;
    width: 100%;
}

.stat-table-pagination-container {
    display: flex;
    justify-content: space-between;
}

.stat-table-pagination-settings {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stat-table > thead > tr > th {
    border-bottom: solid 2px lightseagreen;
    background: white;
    color: black;
    font-weight: bold;

    height: 50px;

    border-right: 1px solid lightseagreen;

    padding: 10px;

    position: sticky;
    top: var(--navbar-height);
}

.stat-table > thead > tr > th:hover {
    background-color: lightgray;
}

.stat-table > thead > tr > th:last-child {
    border-right: none;
}

.stat-table > tbody > tr {
    height: 25px;
}

.stat-table > tbody > tr:nth-child(odd) > td {
    background-color: white;
}

.stat-table > tbody > tr:nth-child(even) > td {
    background-color: #e3e3e3;
}

.stat-table > tbody > tr:hover {
    background-color: darkgray;
}

.stat-table > tbody > tr > td {
    text-align: center;
    font-weight: bold;
}

.stat-table-header {
    cursor: pointer;
}

.stat-table-dk-selectable {
    cursor: pointer;
}

.stat-table-dk-selectable:hover {
    color: white;
    background-color: darkgreen;
}

.stat-table-dk-selectable-cell::after {
    content: " $";
    color: darkgreen;
}

.header-sort-down:after,
.header-sort-up:after {
    content: ' ';
    position: relative;
    left: 2px;
    border: 8px solid transparent;
}

.header-sort-down:after {
    top: 10px;
    border-top-color: black;
}

.header-sort-up:after {
    bottom: 15px;
    border-bottom-color: black;
}

.header-sort-down,
.header-sort-up {
    padding-right: 10px;
}

@media screen and (max-width: 768px) {
    .stat-table-pagination-container {
        flex-direction: column;
    }

    .stat-table-pagination-settings {
        width: 60%;
        margin: auto;
        justify-content: center;
    }

    .stat-table > thead > tr > th {
        top: 0;
    }

    .stat-table-container {
        overflow-x: scroll;
    }
}