.lineup-editor-container {
    position: fixed;
    top: var(--navbar-height);

    width: 100vw;
    height: calc(100vh - var(--navbar-height));
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);

    overflow: scroll;

    background-color: white;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    padding: 25px;
}

.lineup-editor-container::-webkit-scrollbar {
    display: none;
}

.lineup-editor-players {
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.lineup-editor-player {
    width: 95%;
    background-color: white;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    padding: 20px;
}

@media only screen and (min-width: 1024px) {
    .lineup-editor-container {
        width: 750px;
        height: 80vh;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}