.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}