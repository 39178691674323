.sub-menu-wide-container {
    width: 80%;
    margin: auto;
}

.sub-menu-wide-container-show {
    display: block;
}

.sub-menu-wide-container-hide {
    display: none;
}

.sub-menu-group-title {
    font-weight: bold;
    text-align: center;
}

.sub-menu-wide-link-wrapper {
    text-decoration: none;
    color: black;
}

.sub-menu-wide-item {
    display: flex;
    padding: 0 18px;
    font-size: 13px;
    font-weight: bolder;
    letter-spacing: 1.25px;
    line-height: 26px;
    align-items: center;

    text-transform: uppercase;

    border: black solid 2px;
    height: 50px;
    margin-top: 10px;
}

@media only screen and (min-width: 1024px) {
    .sub-menu-wide-container {
        width: 100vw;
        background-color: white;

        position: fixed;
        top: 72px;
        left: 0;

        justify-content: center;

        border-bottom: 1px solid black;

        padding-bottom: 20px;
    }

    .sub-menu-wide-container-show {
        display: flex;
    }

    .sub-menu-group-title {
        font-weight: bold;
    }

    .sub-menu-wide-group {
        width: fit-content;
        padding: 10px;
    }

    .sub-menu-wide-link-wrapper {
        text-decoration: none;
        color: black;
    }

    .sub-menu-wide-link-wrapper:hover {
        text-decoration: underline;
    }

    .sub-menu-wide-item {
        height: 30px;

        border: none;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}