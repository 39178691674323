.lineup-configuration-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#lineup-configuration-range-value {
    position: relative;
    display: block;
    text-align: center;
    font-size: 6em;
    color: #999;
    font-weight: 400;

    margin-top: -40px;
}

.lineup-configuration-container > input[type=range] {
    width: 400px;
    height: 15px;
    -webkit-appearance: none;
    background: #111;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);

    margin: auto;
}

.lineup-configuration-container > input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--gup-green);
    cursor: pointer;
    border: 4px solid #333;
    box-shadow: -407px 0 0 400px var(--gup-green);
}

@media only screen and (min-width: 1024px) {
    .lineup-configuration-container {
        width: 750px;
        margin: auto;
    }
}