.gm-sport-list {
    padding-inline-end: 40px;
}

.gm-slate-option {
    list-style-type: none;

    width: 100%;
    min-height: 50px;

    color: white;
    background-color: black;

    margin-bottom: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;

    cursor: pointer;
}

@media only screen and (min-width: 1024px) {
    .gm-slate-option:hover {
        background-color: white;
        color: black;

        border: black 2px solid;
    }
}