.nfl-redzone-advantage-container {
    padding: 10px 20px 10px 20px;
}

.nfl-redzone-advantage-player-stats {
    width: 100%;

    display: flex;

    flex-wrap: wrap;

    justify-content: space-between;
}

.nfl-redzone-advantage-stat-group {

    text-align: center;
}

.nfl-redzone-advantage-table {
    height: 50vh;
    overflow-y: scroll;
}

.nfl-redzone-advantage-table > div > table > thead > tr > th {
    position: sticky !important;
    top: 0 !important;
}

@media only screen and (min-width: 1024px) {
    .nfl-redzone-advantage-player-stats {
        width: 100%;

        display: flex;

        flex-wrap: nowrap;

        justify-content: space-between;
    }

    .nfl-redzone-advantage-stat-group {
        width: 32%;

        text-align: center;
    }
}