.nfl-scouting-report-displays {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding: 0px 50px 0px 0px;
}

nfl-scouting-report-schedule-container {
}

.nfl-scouting-report-team-stats-container {
}

.nfl-scouting-report-league-stats-container {
    width: 90vw;
}

@media only screen and (min-width: 1420px) {
    .nfl-scouting-report-displays {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .nfl-scouting-report-league-stats-container {
        max-width: 40vw;
    }
}