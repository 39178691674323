.season-select-filter-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.season-select-filter-option {
    width: 50px;
    height: 25px;

    cursor: pointer;

    border: 2px darkgray solid;

    font-weight: bold;

    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 5px;
}

.season-select-filter-selected {
    background-color: darkgray;
}