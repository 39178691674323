.testimonial-slider {
    height: 50vh;
    background: #e3e7ea;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.testimonial-quotation-icon {
    margin-top: 20px;

    color: var(--gup-green);

    width: 75px;
    height: 75px;
}

.testimonial-content {
    text-align: center;
    width: 90%;
}

.testimonial-quote {
    font-weight: bold;
    font-size: 24px;
}

.testimonial-author {
}

@media only screen and (min-width: 768px) {
    .testimonial-slider {
        height: 35vh;
    }
}

@media only screen and (min-width: 1000px) {
    .testimonial-slider {
        height: 25vh;
    }

    .testimonial-content {
        width: 65%;
        margin: inherit;
    }
}