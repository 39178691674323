.gm-lineup-header {
    color: white;
    padding: 10px;

    margin-bottom: 3px;

    cursor: pointer;

    /* If you want to implement it in very old browser-versions */
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* The rule below is implemented in most browsers by now */
    user-select: none;
}

.gm-lineup-header-valid {
    background-color: darkgreen;
}

.gm-lineup-header-soft-invalid {
    background-color: #CCCC00;
    color: black;
}

.gm-lineup-header-hard-invalid {
    background-color: darkred;
}

.gm-lineup-summary {
    display: flex;
}

.gm-lineup-summary-content {
    width: 95%;
}

.gm-lineup-summary-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gm-lineup-toggle {
    font-size: 12px;
    margin-top: 5px;
}

.gm-lineup-players {
    display: none;
    padding: 10px;
}

.gm-lineup-players-show {
    display: block;
}

.gm-lineup-table {
    width: 100%;
}

@media only screen and (min-width: 1024px) {
    .gm-lineup-header {
        display: none;
    }

    .gm-lineup-players {
        display: block;
    }

    .gm-lineup-container {
        width: 500px;

        border-radius: 10px;

        margin-bottom: 50px;
    }

    .gm-lineup-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .gm-lineup-valid {
        border: var(--gup-green) solid 2px;
    }

    .gm-lineup-soft-invalid {
        border: orange solid 2px;
    }

    .gm-lineup-hard-invalid {
        border: red solid 2px;
    }
}