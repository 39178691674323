.gm-lineups-container {
    display: flex;
    flex-direction: column;
}

.gm-lineup-summary-header {
    color: white;
    background-color: darkslategray;
    padding: 10px;

    margin-bottom: 3px;

    cursor: pointer;

    /* If you want to implement it in very old browser-versions */
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* The rule below is implemented in most browsers by now */
    user-select: none;
}

.gm-lineup-summary-players {
    padding: 10px;
}

.gm-lineup-summary-table {
    width: 100%;
}

.gm-lineups-spinner {
    width: 100%;
    display: flex;
    justify-content: center;

    padding: 10px;
}

@media only screen and (min-width: 1024px) {
    .gm-lineups-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly
    }

    .gm-lineups-spinner {
        margin-top: -50px;
    }

    .gm-lineups-summaries {
        width: 90vw;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .gm-lineups-summary {
        width: 50%;
        padding: 5px;
    }
}