.gm-builds-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;

    margin: 20px 0 20px 0;
}

.gm-build-select {
    margin-right: 5px;
    margin-bottom: 5px;
    border: black solid 1px;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    cursor: pointer;
}

.gm-build-selected {
    color: white;
    background-color: black;
}

@media only screen and (min-width: 1024px) {
    .gm-view-lineups-header-container {
        width: 750px;
        margin: auto;
    }
}