.button {
  border: 2px solid black;
  color: white;
  background-color: black;

  display: flex;
  justify-content: center;
  height: 45px;
  width: 180px;
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 1.14px;
  line-height: 20px;
  text-transform: uppercase;

  align-items: center;
  transition: all 0.2s;
  cursor: pointer;

  text-decoration: none;

  margin-left: auto;
  margin-right: auto;
}

.button:hover {
  color: black;
  background: var(--gup-green);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
}