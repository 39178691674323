.pga-live-tournament-tool-page-container {
    width: 95vw;
    margin: 10px auto auto auto;

    display: flex;
    flex-wrap: wrap;
}

.pga-live-tournament-tool-filters {
    width: 100%;
    flex-basis: 100%;

    display: flex;
    flex-wrap: wrap;
}

.pga-live-tournament-tool-filters-player-search {
    width: 100%;
}

.pga-live-tournament-tool-filters-event-select {
    width: 100%;
}

.pga-scorecards-and-filters-container {
    width: 100%;
}

@media only screen and (min-width: 1100px) {
    .pga-live-tournament-tool-page-container {
        width: 95vw;
        margin: 100px auto auto auto;

        display: flex;
        flex-wrap: wrap;
    }

    .pga-live-tournament-tool-filters {
        width: 100%;
        flex-basis: 100%;

        display: flex;
    }

    .pga-live-tournament-tool-filters-player-search {
        width: 75%;
        margin-right: 10px;
    }

    .pga-live-tournament-tool-filters-event-select {
        width: 25%;
    }

    .pga-scorecards-and-filters-container {
        width: 100%;
    }
}