.see-more-button {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.see-more-content {
    display: inline-block;
    width: 100%;
}