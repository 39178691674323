.gm-exposure-table-cell {
    width: 80%;
    display: flex;

    flex-direction: column;

    justify-content: space-evenly;

    padding-left: 10px;

    border-right: 1px dashed black;
}

.gm-exposure-table-cell-actions {
    width: 40%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.gm-exposure-table-lock-button {
    margin-left: 5px;

    cursor: pointer;
}

.gm-exposure-table-value-input {
    width: 50px;
    padding: 0;
    text-align: center;
    font-size: 24px;
}

.gm-exposure-table-value-input::-webkit-outer-spin-button,
.gm-exposure-table-value-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.gm-exposure-table-value-input[type=number] {
    -moz-appearance: textfield;
}

.gm-exposure-table-value-perc {
    margin: 0;
    text-align: center;
}

.gm-exposure-table-exposure-buttons {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
}

.gm-exposure-table-increment {
    display:inline-block;
    width:30px;
    height:30px;
    padding:7px;
    margin-bottom: 5px;
    box-sizing:border-box; /*Remove this if you don't want space to be included in the size*/

    background: linear-gradient(white, white) content-box, linear-gradient(white, white) content-box, #77dd77;
    background-position:center;
    background-size: 100% 3px, 3px 100%;
    background-repeat:no-repeat;

    border-radius: 50%;

    cursor: pointer;
}

.gm-exposure-table-decrement {
    display:inline-block;
    width:30px;
    height:30px;
    padding:7px;
    box-sizing:border-box; /*Remove this if you don't want space to be included in the size*/

    background: linear-gradient(white, white) content-box, #ff6961;
    background-position:center;
    background-size: 100% 3px, 3px 100%;
    background-repeat:no-repeat;

    border-radius: 50%;

    cursor: pointer;
}

@media only screen and (min-width: 1024px) {

}