.podcast-player {
    width: 90%;
    margin: 45px auto auto;
}

.podcast-list-container {
    width: 100%;

    padding: 10px;
}

.podcast-list {
    list-style: none;
    padding: 0;
}

.podcast-list-item {}

.podcast-list-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.podcast-list-rule {
    width: 95%;
}

.podcast-list-content-play {
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    display: flex;
    align-items: center;

    width: fit-content;

    border: 1px solid gray;
    padding: 5px;
    border-radius: 25px;

    margin-top: 10px;
}

.podcast-list-content-play-icon {
    display: inline-block;
    margin-right: 5px;
}

.podcast-list-content-info {}

.podcast-list-content-title {
    margin-top: 5px;
    font-weight: bold;
}

.podcast-list-content-description {
    margin-top: 10px;
    overflow-wrap: break-word;
}

.podcast-list-content-description  p {
    overflow-wrap: break-word;
    width: 100%;
}

.podcast-list-content-pub-date {}

.podcast-list-content-runtime {}

@media only screen and (min-width: 1024px) {
    .podcast-player {
        width: 600px;
        height: 195px;
    }

    .podcast-list-rule {
        width: 99%;
    }
}