.multi-select-filter {
    display: flex;
    max-width: 400px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.multi-select-filter-option {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid black;
    border-radius: 15px;

    cursor: pointer;

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.multi-select-filter-option-selected {
    background-color: lightskyblue;
}