.shadow-card-container {
    width: fit-content;
    height: fit-content;

    padding: 10px;
    margin-bottom: 10px;

    border-radius: 20px;

    -webkit-box-shadow: 0px 6px 10px -4px rgba(0,0,0,0.61);
    box-shadow: 0px 6px 10px -4px rgba(0,0,0,0.61);
}