.gm-sport-list {
    padding-inline-end: 40px;

    display: flex;

    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-evenly;
}

.gm-sport-option {
    width: 100%;
    height: 100%;

    list-style-type: none;

    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;

    cursor: pointer;
}