.gm-player-table-row-unselected {
    padding: 5px 0 5px 0;
    min-height: 60px;

    border: 1px solid black;
    border-bottom: none;

    display: flex;
}

.gm-player-table-row-selected {
    padding: 5px 0 5px 0;
    min-height: 60px;

    border: 1px solid black;
    border-bottom: none;

    display: flex;

    background-color: #77dd77;
}

.gm-player-table-cell {
    width: 80%;
    display: flex;

    flex-direction: column;

    justify-content: space-between;

    padding-left: 10px;

    border-right: 1px dashed black;
}

.gm-player-table-cell-actions {
    width: 20%;

    display: flex;

    justify-content: center;
    align-items: center;
}

.gm-player-table-select {
    display:inline-block;
    width:40px;
    height:40px;
    padding:10px;
    box-sizing:border-box; /*Remove this if you don't want space to be included in the size*/

    border-radius: 50%;

    cursor: pointer;
}

.gm-player-table-row-unselected .gm-player-table-select {
    background: linear-gradient(white, white) content-box, linear-gradient(white, white) content-box, #77dd77;

    background-position:center;
    background-size: 100% 3px, 3px 100%;
    background-repeat:no-repeat;
}

.gm-player-table-row-selected .gm-player-table-select {
    background: linear-gradient(white, white) content-box, #ff6961;

    background-position:center;
    background-size: 100% 3px, 3px 100%;
    background-repeat:no-repeat;
}