.team-select-filter {
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

.team-select-filter-option {
    width: 50px;
    height: auto;

    opacity: 0.3;

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.team-select-filter-option:hover {
    cursor: pointer;
}

.team-select-filter-option-selected {
    opacity: 1;
}